import { TFunction } from "next-i18next";
import { EHttpStatus } from "../backend-api/error-types";
export interface ErrorMessage {
  title: string;
  description: string;
}

const resolveErrorMessageBasedOnStatusCode = (statusCode: number, t: TFunction): ErrorMessage => {
  switch (statusCode) {
    case EHttpStatus.BadRequest:
    case EHttpStatus.Forbidden:
    case EHttpStatus.NotFound:
      return {
        title: t("title.404"),
        description: t("description.404"),
      };
    case EHttpStatus.NotPaid:
      return {
        title: t("title.402"),
        description: t("description.402"),
      };
    case EHttpStatus.Gone:
      return {
        title: t("title.410"),
        description: t("description.410"),
      };
    default:
      return {
        title: t("title.5XX"),
        description: t("description.5XX"),
      };
  }
};

export const errorResolver = {
  resolveErrorMessageBasedOnStatusCode,
};
