import React from "react";
import { Button } from "../../elements/Button";
import { styles } from "./Error.styles";

export interface IErrorProps {
  title: string;
  description: string;
  buttonHref: string;
  buttonTitle: string;
  errorImageProps: IErrorImageProps;
}

export interface IErrorImageProps {
  desktopImage: string;
  mobileImage: string;
  desktopImageWidth: string;
  desktopImageHeight: string;
  mobileImageWidth: string;
  mobileImageHeight: string;
  alt: string;
  title: string;
}

export const Error: React.FC<IErrorProps> = ({ title, description, buttonHref, buttonTitle, errorImageProps }) => {
  return (
    <div css={styles.root()}>
      <h1>{title}</h1>
      <img css={styles.image(errorImageProps)} alt={errorImageProps.alt} title={errorImageProps.title} />
      <p>{description}</p>
      <div css={styles.buttonWrapper()}>
        <Button href={buttonHref}>{buttonTitle}</Button>
      </div>
    </div>
  );
};
