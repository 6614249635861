import {
  MODULE_PADDING_MOBILE,
  MODULE_PADDING_DESKTOP,
} from "@carfax/web-ui/components/ModulesWithTheme/ModulesWithTheme.style";
import { colors, mediaQuery } from "@carfax/web-ui/core/styles";
import { css } from "@emotion/react";

export const styles = {
  root: () =>
    css({
      background: colors.backgroundBlue.hex,
      padding: `${MODULE_PADDING_MOBILE} 0`,
      [mediaQuery.desktopOrLarger]: {
        padding: `${MODULE_PADDING_DESKTOP} 0`,
      },
    }),
};
