import React from "react";
import { observer, useTranslation } from "app";
import VinSearch, { SearchDisplayType } from "components/VinSearch/VinSearch";
import { VinSearchCtxProvider } from "../VinSearchContext";
import SampleReport from "components/SampleReport";
import { VinSearchBlue } from "@carfax/web-ui/components/BlueVinSearch";
import { styles } from "./VinWidget.style";

const VinWidget: React.FC = observer(() => {
  const { t } = useTranslation(["common"]);

  const vinSearchProps = {
    displayType: SearchDisplayType.DefaultWithoutContainer,
  };
  return (
    <>
      <div css={styles.root()} data-testid="vin-widget">
        <VinSearchBlue
          header={t("vinWidget.overFooterHeadline")}
          modalText={<SampleReport>{t("vinWidget.sampleReportLink.text")}</SampleReport>}
        >
          <VinSearchCtxProvider values={{ status: null, errorMsg: null }}>
            <VinSearch {...vinSearchProps} customPlaceholder={t("vinWidget.vinInputLabel.part1")} />
          </VinSearchCtxProvider>
        </VinSearchBlue>
      </div>
    </>
  );
});
export default VinWidget;
