import { css } from "@emotion/react";
import { colors, fontSizes, letterSpacings, lineHeights, mediaQuery, spacing } from "../../core";

export const styles = {
  root: () =>
    css({
      background: colors.backgroundBlue.hex,
    }),
  wrapper: () =>
    css({
      maxWidth: "400px",
      margin: "0 auto",
    }),
  header: () =>
    css({
      color: colors.white.hex,
      textAlign: "center",
      marginBottom: spacing.baseUnitMobile3,
      fontSize: fontSizes.heading.H1.mobile,
      lineHeight: lineHeights.heading.H1.mobile,
      fontWeight: "bold",
      [mediaQuery.desktopOrLarger]: {
        fontSize: fontSizes.heading.H3.desktop,
        lineHeight: lineHeights.heading.H3.desktop,
      },
    }),

  modal: () =>
    css({
      color: colors.white.hex,
      display: "block",
      cursor: "pointer",
      marginTop: spacing.baseUnitMobile3,
      textAlign: "center",
      fontWeight: "bold",
      fontsize: fontSizes.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
      lineHeight: lineHeights.body.mouse,
      [mediaQuery.desktopOrLarger]: {
        lineHeight: lineHeights.body.horse,
      },
      "&:hover": {
        textDecoration: "underline",
      },
    }),
};
