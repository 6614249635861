export type httpMethods = "GET" | "POST" | "PUT";

export class BackendRequestBuilder {
  private readonly baseUrl;

  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  public buildRequestConfig(
    url: string,
    method?: httpMethods,
    mediaType?: string,
    params?: any,
    timeout?: number,
    body?: JSON,
    contentType?: "multipart/form-data" | "application/json"
  ) {
    const tenSeconds = 10000;
    return {
      url,
      method: method || "GET",
      headers: {
        Accept: mediaType || "application/json",
        "Content-Type": contentType || "application/json",
      },
      params: params || {},
      withCredentials: true,
      timeout: timeout || tenSeconds,
      data: body || null,
      baseURL: this.baseUrl,
    };
  }
}
