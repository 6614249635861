import { NextPage } from "next";
import { INextPageProps, useTranslation } from "app";
import Seo from "domain/SeoAndAnalytics/Seo";
import { errorResolver } from "../utils/error/error-resolver";
import { languageConfig } from "../i18n/language-config";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { i18n } from "next-i18next";
import { EHttpStatus } from "../utils/backend-api/error-types";
import NotFound from "../components/Error/NotFound";
import ReportExpired from "../components/Error/ReportExpired";
import InternalError from "../components/Error/InternalError";

interface Props extends INextPageProps {
  statusCode: number;
  blogPosts?: any;
}

const Error: NextPage<Props> = ({ statusCode }) => {
  const { t } = useTranslation(["page-error", "page-index"]);
  const errorMessage = errorResolver.resolveErrorMessageBasedOnStatusCode(statusCode, t);

  const getErrorByStatusCode = () => {
    switch (statusCode) {
      case EHttpStatus.NotFound:
        return <NotFound />;
      case EHttpStatus.Gone:
      case EHttpStatus.NotPaid:
        return <ReportExpired />;
      default:
        return <InternalError />;
    }
  };

  return (
    <>
      <Seo title={errorMessage.title} />
      {getErrorByStatusCode()}
    </>
  );
};

export const getStaticProps = async ({ res, err, params }) => {
  let lang;

  if (params && params.lang) {
    lang = params.lang;
  } else {
    lang = i18n?.language || languageConfig.getDefaultLocale();
  }

  const statusCode = res?.statusCode || err?.statusCode || null;

  return {
    props: {
      ...(await serverSideTranslations(lang, ["common", "page-index", "page-error"])),
      statusCode,
    },
  };
};

export default Error;
