import React, { ReactNode } from "react";
import { Container } from "../../elements/Container";
import { styles } from "./BlueVinSearch.style";

export interface BlueVinSearchProps {
  header: string;
  modalText: ReactNode;
  children: ReactNode;
}

export const BlueVinSearch: React.FC<BlueVinSearchProps> = ({ header, children, modalText }) => {
  return (
    <div css={styles.root()}>
      <Container>
        <div css={styles.header()}>{header}</div>
        <div css={styles.wrapper}>
          {children}
          <div css={styles.modal()}>{modalText}</div>
        </div>
      </Container>
    </div>
  );
};
