import React from "react";
import { useTranslation } from "../../../app";
import { frontendUrlBuilder } from "../../../utils/routing/frontend-url-builder";
import { Error } from "@carfax/web-ui/components/Error";
import { IErrorImageProps } from "@carfax/web-ui/components/Error/Error";
import { Container } from "@carfax/web-ui/elements/Container";

const NotFound: React.FC = () => {
  const { t } = useTranslation(["page-error"]);
  const buttonHref = frontendUrlBuilder.homepageUrlWithLang();
  const errorImageProps: IErrorImageProps = {
    desktopImage: "/static/images/error/not-found-desktop-2x.png",
    mobileImage: "/static/images/error/not-found-mobile-2x.png",
    desktopImageHeight: "297px",
    desktopImageWidth: "447px",
    mobileImageHeight: "203px",
    mobileImageWidth: "307px",
    alt: "carfox holding wheel 404",
    title: "carfox holding wheel 404",
  };

  return (
    <Container paddingTop={["baseUnitMobile4", "baseUnitMobile6"]}>
      <Error
        title={t("title.404")}
        description={t("description.404")}
        buttonTitle={t("button.404")}
        buttonHref={buttonHref}
        errorImageProps={errorImageProps}
      />
    </Container>
  );
};

export default NotFound;
