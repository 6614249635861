import { BackendApi } from "../../../utils/backend-api/backend-api";
import { ApiConfig } from "../../../utils/backend-api/api-config";
import { ApiRoutes } from "../../../utils/backend-api/api-routes";

const backendApi = new BackendApi(ApiConfig.buildBackendUrlForClient());
const getVinregByTransactionId = async (transactionId) => {
  return backendApi.request<string>(ApiRoutes.getVinregByTransactionUrl(transactionId));
};
const getVinregByReportId = async (reportId) => {
  return backendApi.request<string>(ApiRoutes.getVinregByReportId(reportId));
};

export const reportExpiredUtils = {
  getVinregByReportId,
  getVinregByTransactionId,
};
