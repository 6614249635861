import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../app";
import RouterService from "../../../domain/Routing/utils/router-service";
import { UrlQueryParameters } from "../../../utils/config/urlQueryParameters";
import VinWidget from "../../VinSearch/VinWidget";
import { Error } from "@carfax/web-ui/components/Error";
import { reportExpiredUtils } from "./utils";
import { Routes } from "../../../utils/config/routes";
import { IErrorImageProps } from "@carfax/web-ui/components/Error/Error";
import { Container } from "@carfax/web-ui/elements/Container";

const ReportExpired: React.FC = () => {
  const { t } = useTranslation(["page-error"]);
  const transactionId = RouterService?.getQueryObject()?.[UrlQueryParameters.TRANSACTION_ID];
  const reportId = RouterService?.getQueryObject()?.[UrlQueryParameters.REPORT_ID];
  const [vinreg, setVinreg] = useState("");
  const errorImageProps: IErrorImageProps = {
    desktopImage: "/static/images/error/report-expired-desktop.png",
    mobileImage: "/static/images/error/report-expired-mobile.png",
    desktopImageHeight: "278px",
    desktopImageWidth: "169px",
    mobileImageHeight: "213px",
    mobileImageWidth: "129px",
    alt: "carfox holding clock",
    title: "carfox holding clock",
  };

  useEffect(() => {
    getVinreg();
  }, []);

  const getVinreg = async () => {
    return reportId
      ? setVinreg(await reportExpiredUtils.getVinregByReportId(reportId))
      : setVinreg(await reportExpiredUtils.getVinregByTransactionId(transactionId));
  };

  return (
    <>
      <Container paddingTop={["baseUnitMobile4", "baseUnitMobile6"]}>
        <Error
          title={t("title.410")}
          description={t("description.410")}
          buttonTitle={t("button.410")}
          buttonHref={Routes.reactivateReportPayment(vinreg, false)}
          errorImageProps={errorImageProps}
        />
      </Container>
      <VinWidget />
    </>
  );
};

export default ReportExpired;
