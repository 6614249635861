import { languageConfig } from "../i18n/language-config";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Error from "./_error";
import { EHttpStatus } from "utils/backend-api/error-types";
import { Locales } from "i18n/country";

export default Error;

export const getStaticProps = async () => {
  const langs = languageConfig.getLocales();

  const translations = await Promise.all(
    langs.map((locale) => {
      return serverSideTranslations(locale, ["common", "page-index", "page-error"]);
    }, [])
  );

  const allTranslation = translations.find((tr) => tr._nextI18Next.initialLocale === Locales.USA);

  translations.forEach((tr) => {
    const { initialLocale, initialI18nStore } = tr._nextI18Next;
    if (initialLocale === Locales.USA) return;
    allTranslation._nextI18Next.initialI18nStore = {
      ...allTranslation._nextI18Next.initialI18nStore,
      [initialLocale]: initialI18nStore[initialLocale],
    };
  });

  return {
    props: {
      ...allTranslation,
      statusCode: EHttpStatus.NotFound,
    },
  };
};
