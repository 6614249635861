import { css } from "@emotion/react";
import { mediaQuery, spacing, fontSizes, letterSpacings, lineHeights } from "../../core";
import { IErrorImageProps } from "./Error";

export const styles = {
  root: () =>
    css({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      h1: {
        marginBottom: spacing.baseUnitMobile3,
        textAlign: "center",
      },
      p: {
        textAlign: "center",
        fontSize: fontSizes.body.horse,
        letterSpacing: letterSpacings.body.horse,
        lineHeight: lineHeights.body.horse,
        maxWidth: "712px",
      },
    }),
  image: (errorImageProps: IErrorImageProps) =>
    css({
      display: "block",
      margin: "auto",
      width: errorImageProps.mobileImageWidth,
      height: errorImageProps.mobileImageHeight,
      outline: "none",
      marginBottom: spacing.baseUnitMobile3,
      content: `url(${errorImageProps.mobileImage})`,
      [mediaQuery.tabletOrLarger]: {
        width: errorImageProps.desktopImageWidth,
        height: errorImageProps.desktopImageHeight,
        content: `url(${errorImageProps.desktopImage})`,
      },
    }),
  buttonWrapper: () =>
    css({
      marginBottom: spacing.baseUnitMobile10,
      zIndex: "10",
    }),
};
